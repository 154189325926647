"use client"

import ErrorBoundary from "../../ui/errors/ErrorBoundaryView"

export default function Error({
  error,
  reset,
}: {
  error: Error
  reset: () => void
}) {
  return <ErrorBoundary error={error} reset={reset} />
}
